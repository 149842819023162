
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/risk-and-exception/exception-handling/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiExceptionHandlings } from "@/core/api";
// import addExceptionHandlingsModal from "@/views/risk-and-exception/exception-handling/AddExceptionHandlingsModal.vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { ExceptionHandlingTable } from "@/core/directive/interface/exceptionHandling";
import store from "@/store";
import { getExceptionHandlingProcessMap } from "@/core/directive/function/exceptionHandling";
import {
  commonChangeFilterAddDate,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "risk-and-exception-exception-handling",
  components: {
    MBDatatable,
    FilterDropdown,
    // addExceptionHandlingsModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<ExceptionHandlingTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("exceptionHandlings.id"),
        key: "id",
        sortable: true,
      },
      {
        name: t("exceptionHandlings.priority"),
        key: "level",
        sortable: false,
      },
      {
        name: t("exceptionHandlings.summary"),
        key: "title",
        sortable: false,
      },
      {
        name: t("salesOrder.orderNumber"),
        key: "sale_order_number",
        sortable: false,
      },
      {
        name: t("exceptionHandlings.date"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("exceptionHandlings.status"),
        key: "progress",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      type: [],
      progress: [],
      solution: [],
    });

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
      if (
        options.value.type.length === 0 ||
        options.value.progress.length === 0 ||
        options.value.solution.length === 0
      ) {
        getOptions();
      }
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getLabel = (val, key) => {
      return options.value[key].find((item) => {
        return item.value == val;
      })?.label;
    };

    const getCompanyList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiExceptionHandlings.getExceptionsList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getCompanyList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "created_at_start") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "created_at_end") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "fulfillment_order_number") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getCompanyList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = (status) => {
      return getExceptionHandlingProcessMap(Number(status), t);
    };

    const getOptions = () => {
      ApiBase.getTaggingData({
        short_key: [
          "exception_handling_type",
          "exception_handling_progress",
          "exception_handling_solution",
        ],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.type = data.data.exception_handling_type.items;
            options.value.progress =
              data.data.exception_handling_progress.items;
            options.value.solution =
              data.data.exception_handling_solution.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getCompanyList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      getLabel,
      options,
    };
  },
});
