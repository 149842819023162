
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  type: string;
  fulfillment_order_number: string;
  progress: string;
  solution: string;
  customer_service_mobile: string;
  sale_order_number: string;
  created_at_start: string;
  created_at_end: string;
}

export default defineComponent({
  name: "filter-sales-invoice-dropdown",
  components: {},
  props: {
    options: { type: Object, required: true },
  },
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      type: "",
      fulfillment_order_number: "",
      progress: "",
      solution: "",
      customer_service_mobile: "",
      sale_order_number: "",
      created_at_start: "",
      created_at_end: "",
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      // getCountryOptions();
    });

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
    };
  },
});
